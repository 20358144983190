import './experience_fl.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Modi from '../src/components/Modi/Modi';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Button from '../src/components/Buttons/Buttons';
import logo_fl from '../src/images/entradas-logo-large-fl.png';
import Hod from '../src/components/Hod/Hod';
import tt from '../src/helpers/translation';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query experienceFL($locale: String!) {
    allRidesTitleBlock(filter: { park: { eq: "FerrariExperience" }, lang: { eq: $locale } }) {
      edges {
        node {
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allRidesMobileTitleBlock(filter: { park: { eq: "FerrariExperience" }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allRidesTitle(filter: { park: { eq: "FerrariExperience" }, lang: { eq: $locale } }) {
      edges {
        node {
          name
          description
          text
        }
      }
    }
    allRidesSeoData(filter: { tag: { eq: "experience_fl" }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allRidesSubBlock(filter: { park: { eq: "FerrariExperience" }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 160, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          seeMore
          located
          features
          possibleFilters1
          possibleFilters2
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allRidesPromotedSubBlock(filter: { park: { eq: "FerrariExperience" }, lang: { eq: $locale } }) {
      edges {
        node {
          description
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 160, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allDistAtraccionesBreadCrumbBlock(
      filter: { park: { eq: "FerrariExperience" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allDistAtraccionesRichData(
      filter: { extra: { eq: "FerrariExperience" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class Experience extends React.Component {
  state = {
    once: false,
    throttleInterval: 150,
    offset: 0,
    partialVisibility: false,
    world: '',
    tipoServicio: '',
    tipoMenu: '',
    initialFilterStateMenu: true,
    initialFilterStateServicios: true,
  };

  handleFilter = (name, tag) => {
    if (tag === 'TIPO DE ATRACCIÓN') {
      const newFilterData = { ...this.state.filterData, tipoServicio: name };
      this.setState({
        tipoServicio: name,
        initialFilterStateMenu: false,
        filterData: newFilterData,
      });
    } else if (tag === 'TIPO DE SERVICIO') {
      const newFilterData = { ...this.state.filterData, tipoMenu: name };
      this.setState({
        tipoMenu: name,
        initialFilterStateServicios: false,
        filterData: newFilterData,
      });
    }
  };

  resetFilter = () => {
    const newFilterData = new Object();
    this.setState({
      initialFilterStateMenu: true,
      initialFilterStateServicios: true,
      filterData: newFilterData,
    });
  };

  render() {
    const heimdallData = {
      name: this.props.data.allRidesTitle.edges[0].node.name,
      subtitle: this.props.data.allRidesTitle.edges[0].node.description.replace(
        /<\/?[^>]+(>|$)/g,
        ''
      ),
      image: {
        url: this.props.data.allRidesTitleBlock.edges[0].node.localImage.childImageSharp.fluid,
      },
      imageMobile: {
        url: this.props.data.allRidesMobileTitleBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
    };

    const freyaDataRides = {
      cards: this.props.data.allRidesSubBlock.edges
        .map(ride => ({
          image: ride.node.localImage.childImageSharp.fluid,
          altImage: ride.node.image_alt,
          imgsize: ride.node.imgsize,
          title: ride.node.title,
          description: ride.node.description,
          seeMore: ride.node.seeMore,
          located: ride.node.located,
          features: ride.node.features,
          possibleFilters: ride.node.possibleFilters1 + ride.node.possibleFilters2,
          buttons: {
            cta: ride.node.buttons.cta,
            size: ride.node.buttons.size,
            color: ride.node.buttons.color,
            ctaText: ride.node.buttons.ctaText,
          },
        }))
        .filter(item => {
          const setFilters = [];
          for (const prop in this.state.filterData) setFilters.push(this.state.filterData[prop]);
          if (!setFilters.length) return item;
          if (setFilters.every(elem => item.possibleFilters.indexOf(elem) > -1)) return item;
        }),
    };

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: this.props.data.allRidesPromotedSubBlock.edges.map(shop => ({
        image: shop.node.localImage.childImageSharp.fluid,
        altImage: shop.node.image_alt,
        title: shop.node.description,
        imgsize: 'promoted',
        buttons: {
          cta: tt('/entradas', this.props.pageContext.locale),
          size: 'alone',
          color: 'white',
          ctaText: tt('VER PROMO', this.props.pageContext.locale),
        },
      })),
    };

    const buttons = {
      cta: tt('/entradas/ferrari-land', this.props.pageContext.locale),
      size: 'alone',
      color: 'orange',
      ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
    };

    const modiData = {
      Image: logo_fl,
      Text: this.props.data.allRidesTitle.edges[0].node.text,
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allRidesSeoData.edges[0].node._0.title}
          description={this.props.data.allRidesSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allDistAtraccionesRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allRidesTitleBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="experience-subpage-content">
              <div className="Asgard-box">
                <Modi data={modiData} />
              </div>
              <div className="kidsrides-content">
                <Button
                  link={buttons.cta}
                  size={buttons.size}
                  color={buttons.color}
                  text={buttons.ctaText}
                />

                <BlueArrow />
                <NeoAesir
                  locale={this.props.pageContext.locale}
                  pageName={this.props.pageContext.pageName}
                  title={this.props.data.allDistAtraccionesBreadCrumbBlock.edges[0].node.name}
                />
                <Freya data={freyaDataRides} />
                <div className="rides-content-promo">
                  <H4 title={tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale)} />
                  <Freya data={freyaData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
Experience.propTypes = {
  RidesData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default Experience;
